"use client"
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import banner from '../../../public/images/home/homeBanner.webp'
import dynamic from 'next/dynamic'
const Comparision = dynamic(() => import('./Comparision'));
const ComparisionLoading = dynamic(() => import('../loadings/ComparisionLoading'));


const Banner = () => {
  const [isClient, setIsClient] = useState(false)
 
useEffect(() => {
  setIsClient(true)
}, [])

  return (
    <section >
      <div className='mx-auto flex justify-center items-center w-full'>
        <Image
          src={banner}
          alt="banner"
          width={1440}
          height={467}
          className="w-full  object-cover"
          priority
        />
      </div>
      <div className="relative mt-5 lg:-mt-20  mx-2 lg:mx-auto flex justify-center items-center">
        {isClient?<Comparision/>:<ComparisionLoading/>}
      </div>
    </section>
  )
}

export default Banner
