import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/carIcon.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/home/most-searched-car.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/maruti.png");
;
import(/* webpackMode: "eager" */ "/app/public/images/swift.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/home/Banner.tsx");
